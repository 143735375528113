import React, { useState, useEffect } from "react";

import CommunityDialogGallery from "./CommunityDialogGallery";

const CommunityMainGallery = ({ community, place, imageUrls }) => {
    const [open, setOpen] = useState(false);

    if (imageUrls.length === 0) {
        return null;
    }

    return (
        <>
            <div className="relative mt-2 h-[300px] sm:h-[500px] rounded overflow-hidden bg-gray-100">
                <Images imageUrls={imageUrls} />

                <button
                    className="absolute right-4 bottom-2 bg-primary-50 px-2 py-1 rounded hover:bg-primary-100"
                    onClick={() => setOpen(true)}
                >
                    View {imageUrls.length} Photos
                </button>
            </div>

            {open ? (
                <CommunityDialogGallery
                    open={open}
                    onClose={() => setOpen(false)}
                    community={community}
                    imageUrls={imageUrls}
                />
            ) : null}

            {/* This is needed for the google place library */}
            <div id={"id-community-" + community.nameId}></div>
        </>
    );
};

const Images = ({ imageUrls }) => {
    if (imageUrls.length === 0) {
        return <div></div>;
    }

    if (imageUrls.length === 1) {
        return (
            <div className="grid grid-cols-1 gap-2">
                <img
                    src={imageUrls[0]}
                    alt="Main Picture"
                    className="w-full h-[300px] sm:h-[500px] object-cover"
                />
            </div>
        );
    }

    if (imageUrls.length === 2) {
        return (
            <div className="grid grid-cols-2 gap-2">
                <img
                    src={imageUrls[0]}
                    alt="Main Picture"
                    className="w-full h-[300px] sm:h-[500px] object-cover"
                />

                <img
                    src={imageUrls[1]}
                    alt="Main Picture"
                    className="w-full h-[300px] sm:h-[500px] object-cover"
                />
            </div>
        );
    }

    if (imageUrls.length === 3) {
        return (
            <div className="grid grid-cols-4 gap-2">
                <img
                    src={imageUrls[0]}
                    alt="Main Picture"
                    className="w-full h-[300px] sm:h-[500px] object-cover col-span-2 row-span-2"
                />

                <img
                    src={imageUrls[1]}
                    alt="Main Picture"
                    className="w-full h-[146px] sm:h-[246px] object-cover col-span-2"
                />

                <img
                    src={imageUrls[2]}
                    alt="Main Picture"
                    className="w-full h-[146px] sm:h-[246px] object-cover col-span-2"
                />
            </div>
        );
    }

    if (imageUrls.length === 4) {
        return (
            <div className="grid grid-cols-4 gap-2">
                <img
                    src={imageUrls[0]}
                    alt="Main Picture"
                    className="w-full h-[300px] sm:h-[500px] object-cover col-span-2 row-span-2"
                />

                <img
                    src={imageUrls[1]}
                    alt="Main Picture"
                    className="w-full h-[146px] sm:h-[246px] object-cover col-span-2"
                />

                <img
                    src={imageUrls[2]}
                    alt="Main Picture"
                    className="w-full h-[146px] sm:h-[246px] object-cover"
                />

                <img
                    src={imageUrls[3]}
                    alt="Main Picture"
                    className="w-full h-[146px] sm:h-[246px] object-cover"
                />
            </div>
        );
    }

    // 5 or more
    return (
        <div className="grid grid-cols-4 gap-2">
            <img
                src={imageUrls[0]}
                alt="Main Picture"
                className="w-full h-[300px] sm:h-[500px] object-cover col-span-2 row-span-2"
            />

            <img
                src={imageUrls[1]}
                alt="Main Picture"
                className="w-full h-[146px] sm:h-[246px] object-cover"
            />

            <img
                src={imageUrls[2]}
                alt="Main Picture"
                className="w-full h-[146px] sm:h-[246px] object-cover"
            />

            <img
                src={imageUrls[3]}
                alt="Main Picture"
                className="w-full h-[146px] sm:h-[246px] object-cover"
            />

            <img
                src={imageUrls[4]}
                alt="Main Picture"
                className="w-full h-[146px] sm:h-[246px] object-cover"
            />
        </div>
    );
};

export default CommunityMainGallery;
