import React from "react";
import styles from "./GetStarted.module.css";

const Verified = ({ width = 36, height = 45 }) => {
    const style = { fill: "#744A9E" };

    return (
        <svg viewBox="0 0 72 90" width={width} height={height}>
            <g>
                <path
                    style={style}
                    d="M36.07,1.39c2.45,0,3.97,2.24,5.47,4.44c0.89,1.31,1.77,2.6,2.34,2.75c0.58,0.16,2.02-0.54,3.44-1.24
		c2.34-1.14,4.66-2.28,6.68-1.09c2.03,1.19,2.16,3.79,2.3,6.39c0.08,1.55,0.16,3.1,0.56,3.49c0.39,0.4,1.94,0.48,3.49,0.56
		c2.6,0.13,5.21,0.27,6.39,2.3c1.18,2.02,0.05,4.35-1.09,6.68c-0.7,1.43-1.4,2.86-1.24,3.44c0.16,0.57,1.45,1.45,2.75,2.34
		c2.21,1.5,4.44,3.02,4.44,5.47c0,2.45-2.24,3.97-4.44,5.47c-1.31,0.89-2.6,1.77-2.75,2.34c-0.16,0.58,0.54,2.02,1.24,3.44
		c1.14,2.34,2.28,4.66,1.09,6.68c-1.19,2.03-3.79,2.16-6.39,2.3c-1.55,0.08-3.1,0.16-3.49,0.56c-0.39,0.4-0.47,1.94-0.56,3.49
		c-0.13,2.6-0.27,5.21-2.3,6.39c-2.02,1.18-4.35,0.05-6.68-1.09c-1.43-0.7-2.86-1.4-3.44-1.24c-0.57,0.16-1.45,1.45-2.34,2.75
		c-1.5,2.21-3.02,4.44-5.47,4.44c-2.45,0-3.97-2.24-5.47-4.44c-0.89-1.31-1.77-2.6-2.34-2.75c-0.58-0.16-2.02,0.54-3.44,1.24
		c-2.34,1.14-4.66,2.28-6.68,1.09c-2.03-1.19-2.16-3.79-2.3-6.39c-0.08-1.55-0.16-3.1-0.56-3.49c-0.4-0.39-1.94-0.48-3.49-0.56
		c-2.6-0.13-5.21-0.27-6.39-2.3c-1.18-2.02-0.05-4.35,1.09-6.68c0.7-1.43,1.4-2.86,1.24-3.44c-0.16-0.57-1.45-1.45-2.75-2.34
		c-2.21-1.5-4.44-3.02-4.44-5.47c0-2.45,2.24-3.97,4.44-5.47c1.31-0.89,2.6-1.77,2.75-2.34c0.16-0.58-0.54-2.02-1.24-3.44
		c-1.14-2.34-2.28-4.66-1.09-6.68c1.19-2.03,3.79-2.16,6.39-2.3c1.55-0.08,3.1-0.16,3.49-0.56c0.39-0.39,0.48-1.94,0.56-3.49
		c0.13-2.6,0.27-5.21,2.3-6.39c2.02-1.18,4.35-0.05,6.68,1.09c1.43,0.7,2.86,1.4,3.44,1.24c0.57-0.16,1.45-1.45,2.34-2.75
		C32.1,3.62,33.62,1.39,36.07,1.39L36.07,1.39z M36.07,12.53c-6.74,0-12.83,2.73-17.25,7.14s-7.14,10.51-7.14,17.25
		c0,6.74,2.73,12.83,7.14,17.25c4.41,4.41,10.51,7.14,17.25,7.14c6.73,0,12.83-2.73,17.25-7.14c4.41-4.41,7.14-10.51,7.14-17.25
		c0-6.74-2.73-12.83-7.14-17.25C48.91,15.26,42.81,12.53,36.07,12.53L36.07,12.53z M50.81,22.19c-3.77-3.77-8.98-6.1-14.73-6.1
		c-5.75,0-10.96,2.33-14.73,6.1c-3.77,3.77-6.1,8.98-6.1,14.73c0,5.75,2.33,10.96,6.1,14.73c3.77,3.77,8.98,6.1,14.73,6.1
		c5.75,0,10.96-2.33,14.73-6.1c3.77-3.77,6.1-8.98,6.1-14.73C56.91,31.17,54.58,25.96,50.81,22.19L50.81,22.19z M49.61,31.3
		c0.69-0.69,0.69-1.82,0-2.51c-0.69-0.69-1.82-0.69-2.51,0L32.24,43.65l-7.19-7.19c-0.69-0.69-1.82-0.69-2.51,0
		c-0.69,0.69-0.69,1.82,0,2.51l8.43,8.43l0.01,0.01c0.69,0.69,1.82,0.69,2.51,0l0,0L49.61,31.3z"
                />
                <path
                    style={style}
                    d="M12.2,58.95L1.94,76.41l-0.01,0c-0.5,0.85-0.21,1.94,0.63,2.43c0.35,0.2,0.73,0.28,1.11,0.23L14,78.21
		l4.42,9.56l1.61-0.74l-1.61,0.74c0.41,0.89,1.47,1.28,2.36,0.87c0.39-0.18,0.69-0.49,0.86-0.85l9.48-16.14
		c-0.72-0.82-1.36-1.75-1.97-2.65c-0.27-0.39-0.91-1.39-1.37-1.91c-0.66,0.23-1.73,0.78-2.16,0.99c-2.62,1.28-5.55,2.68-8.36,1.03
		c-2.82-1.65-3.02-4.92-3.17-7.83c-0.02-0.46-0.05-1.53-0.16-2.21C13.42,59.01,12.72,58.98,12.2,58.95z"
                />
                <path
                    style={style}
                    d="M41.04,71.66l9.48,16.14c0.17,0.36,0.47,0.67,0.86,0.85c0.89,0.41,1.95,0.02,2.36-0.87l-1.61-0.74l1.61,0.74
		l4.42-9.56l10.33,0.86c0.37,0.04,0.76-0.03,1.11-0.23c0.85-0.5,1.13-1.59,0.63-2.43l-0.01,0L59.95,58.95
		c-0.52,0.02-1.22,0.06-1.71,0.14c-0.11,0.68-0.14,1.75-0.16,2.21c-0.15,2.92-0.35,6.18-3.17,7.83c-2.81,1.65-5.74,0.25-8.36-1.03
		c-0.43-0.21-1.5-0.76-2.16-0.99c-0.46,0.52-1.1,1.51-1.37,1.91C42.41,69.91,41.77,70.84,41.04,71.66z"
                />
            </g>
        </svg>
    );
};

export default Verified;
