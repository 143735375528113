import React, { useState, useEffect, useContext } from "react";
import ReactMarkdown from "react-markdown";

const google_map_key = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;

import {
    ChevronDownIcon,
    ChevronUpIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/outline";

import { IconButton } from "../ui/Button";

import { RequestInfoCard, UpdateCommunityCard, BookYourTourCard } from "./CTACards";

import { TourRequestDialog } from "../dialogs/TourRequest";

import SeoLinks from "@/components/home/SeoLinks";
import HomeFooter from "@/components/home/HomeFooter";
import ArticleCard from "@/components/article/ArticleCard";

import { getCommunityTypes, CommunityTypesDialog } from "./CommunityUtils";
//import KeysToSeniorLiving from "./KeysToSeniorLiving";
import Rating from "@/ui/Rating";

const CommunityMainInfo = ({ community, place, articles, sidebar = false }) => {
    const [openCommunityTypesDialog, setOpenCommunityTypesDialog] = useState(false);
    const [readMore, setReadMore] = useState(false);
    const address = community.contact ? community.contact[0].address[0] : {};

    return (
        <div className="mt-2">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-8">
                <div className="sm:col-span-2">
                    <div className="flex items-center">
                        <div>
                            <h1 className="pt-1 pb-2 text-xl text-gray-900 font-bold">
                                {community.name}
                            </h1>

                            <div>
                                <div>
                                    {address.street}, {address.city}, {address.state}{" "}
                                    {address.postalCode}
                                </div>

                                <div>{place.formatted_phone_number}</div>

                                {place.website ? (
                                    <a
                                        href={place.website}
                                        className="text-primary-500 underline hover:font-bold break-all"
                                    >
                                        Community Website
                                    </a>
                                ) : null}
                            </div>

                            <div className="mt-4 flex space-x-4 text-md font-semibold text-gray-900">
                                <div className="flex items-center space-x-0 uppercase text-primary-400">
                                    {getCommunityTypes(community.facilityType)}
                                    <IconButton
                                        onClick={() => setOpenCommunityTypesDialog(true)}
                                    >
                                        <QuestionMarkCircleIcon className="w-5 h-5" />
                                    </IconButton>
                                </div>

                                {community.numberOfUnits ? (
                                    <div className="hidden sm:block text-gray-600 whitespace-nowrap">
                                        <span className="mr-2">&bull;</span>
                                        {community.numberOfUnits} Units
                                    </div>
                                ) : null}
                            </div>

                            {community.numberOfUnits ? (
                                <div className="sm:hidden">
                                    {community.numberOfUnits} Units
                                </div>
                            ) : null}
                        </div>

                        <CommunityTypesDialog
                            open={openCommunityTypesDialog}
                            onClose={() => setOpenCommunityTypesDialog(false)}
                        />
                    </div>

                    <hr className="mt-4" />

                    {community.description ? (
                        <div className="mt-4">
                            <div className="flex justify-between">
                                <div>
                                    <div
                                        className={
                                            readMore ? "" : "max-h-72 overflow-y-hidden"
                                        }
                                    >
                                        <ReactMarkdown className="prose max-w-screen">
                                            {community.description}
                                        </ReactMarkdown>
                                    </div>

                                    <button
                                        onClick={() => setReadMore(!readMore)}
                                        className="mt-4 px-2 py-1 flex items-center text-primary-400 rounded hover:bg-gray-100"
                                    >
                                        {readMore ? "Show Less" : "Show More"}
                                        {readMore ? (
                                            <ChevronUpIcon className="ml-2 w-4 h-4" />
                                        ) : (
                                            <ChevronDownIcon className="ml-2 w-4 h-4" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <iframe
                        title="Map"
                        width="100%"
                        height="350"
                        frameBorder="0"
                        style={{ border: 0, marginBottom: 8 }}
                        src={`https://www.google.com/maps/embed/v1/place?key=${google_map_key}&q=${
                            address.street +
                            " " +
                            address.city +
                            " " +
                            address.state +
                            " " +
                            address.postalCode
                        }`}
                        allowFullScreen
                    ></iframe>

                    <div className="mt-2 flex space-x-8">
                        {place.url ? (
                            <a
                                href={place.url}
                                className="text-primary-500 underline hover:font-bold"
                            >
                                View in Google maps
                            </a>
                        ) : null}

                        <div className="flex items-center space-x-2">
                            <div className="text-gray-600">{place.rating}</div>
                            <Rating rating={place.rating} />
                            <div className="text-gray-600">
                                {place.user_ratings_total} Reviews
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 sm:mt-0 space-y-4">
                    <RequestInfoCard
                        community={community}
                        phone={place.formatted_phone_number}
                    />
                    {/*<BookYourTourCard community={community} />*/}
                    <UpdateCommunityCard community={community} />
                </div>
            </div>

            <div className="mt-8 mb-8 grid grid-cols-1 sm:grid-cols-3 gap-x-4">
                {articles
                    ? articles
                          .filter((_, i) => i < 3)
                          .map((r) => <ArticleCard key={r.title} article={r} />)
                    : null}
            </div>

            {/*<KeysToSeniorLiving />*/}

            {!sidebar ? <SeoLinks /> : null}
            {!sidebar ? <HomeFooter /> : null}
        </div>
    );
};

export default CommunityMainInfo;
