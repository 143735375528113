import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "@/utils/link";

import { PencilIcon, ChevronLeftIcon } from "@heroicons/react/outline";

import { toggleFavourite, toggleExOut } from "../../actions/localActions";
import { checkSpecialOffer, getAvailability } from "../home/CommunityCard";
import { PaidLabel } from "../communityPortal/community/EditUtils";
import CommunityActions from "./CommunityActions";

const CommunitySecondaryHeader = ({
    community,
    sidebar = false,
    setOpenGallery,
    preview = false,
    enableEdit = false,
    phone = "",
    onClose,
}) => {
    const dispatch = useDispatch();
    const { favourites, exOut } = useSelector((state) => state.local);

    let numberOfImages = 0;
    let numberOfVideos = 0;
    let numberOfDocuments = 1;
    if (community.media) {
        numberOfImages = community.media.filter(
            (m) => m.mediaType !== "Video" && m.mediaType !== "Document"
        ).length;

        numberOfVideos = community.media.filter((m) => m.mediaType === "Video").length;

        numberOfDocuments += community.media.filter(
            (m) => m.mediaType === "Document"
        ).length;

        numberOfDocuments += community.brochureUrl ? 1 : 0;
        numberOfDocuments += community.careInfoUrl ? 1 : 0;
        numberOfDocuments += community.menuUrl ? 1 : 0;
        numberOfDocuments += community.calendarUrl ? 1 : 0;
    }

    const availability = getAvailability(community);

    // const handleFavourite = (e) => {
    //     e.stopPropagation();
    //     dispatch(toggleFavourite(community.nameId));
    // };

    // const handlePrint = (e) => {
    //     e.stopPropagation();
    //     window.open("/community-mls/" + community.nameId, "_blank");
    // };

    // const handleDownload = (e) => {
    //     e.stopPropagation();
    //     //window.open("/community-mls/" + community.nameId, "_blank");
    // };

    const paidPlan = community.plan && community.plan.startsWith("Paid");
    const waitlist = community.waitlist && paidPlan;

    return (
        <div className="px-2 flex flex-col sm:flex-row space-y-6 sm:space-y-0 items-center justify-between bg-gray-100 rounded">
            <div className="flex items-center space-x-2">
                {sidebar ? (
                    <button onClick={onClose}>
                        <ChevronLeftIcon
                            className={`w-8 h-8 p-1 hover:rounded-full
                                    text-gray-500 hover:text-white hover:bg-primary-400`}
                        />
                    </button>
                ) : null}

                {sidebar ? (
                    <div className="hidden sm:block">
                        <Link href={"/community/" + community.nameId}>
                            <a className="text-primary-500 hover:underline hover:text-primary-400">
                                Open Full Page
                            </a>
                        </Link>
                    </div>
                ) : null}

                {community.onboarded ? (
                    <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400">
                        Claimed
                    </div>
                ) : null}

                {paidPlan && checkSpecialOffer(community) ? (
                    <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400">
                        Special Incentive
                    </div>
                ) : null}

                {paidPlan && availability === "Available Now" ? (
                    <button
                        className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-green-600 hover:bg-green-500"
                        onClick={() => {
                            const e = document.getElementById(
                                "id-section-" +
                                    "Units/Pricing".toLowerCase().replaceAll(" ", "-")
                            );

                            if (e) {
                                const yOffset = -136;

                                const y =
                                    e.getBoundingClientRect().top +
                                    window.pageYOffset +
                                    yOffset;

                                console.log(
                                    "scroll",
                                    e.getBoundingClientRect().top,
                                    window.pageYOffset,
                                    yOffset,
                                    y
                                );

                                window.scrollTo({ top: y, behavior: "smooth" });
                            }
                        }}
                    >
                        Vacancy
                    </button>
                ) : paidPlan && availability !== "Coming Soon" && waitlist ? (
                    <button
                        className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-yellow-600 hover:bg-yellow-500"
                        onClick={() => {
                            const e = document.getElementById(
                                "id-section-" +
                                    "Units/Pricing".toLowerCase().replaceAll(" ", "-")
                            );

                            if (e) {
                                const yOffset = -136;

                                const y =
                                    e.getBoundingClientRect().top +
                                    window.pageYOffset +
                                    yOffset;

                                console.log(
                                    "scroll",
                                    e.getBoundingClientRect().top,
                                    window.pageYOffset,
                                    yOffset,
                                    y
                                );

                                window.scrollTo({ top: y, behavior: "smooth" });
                            }
                        }}
                    >
                        Waitlist
                    </button>
                ) : null}

                {paidPlan && availability === "Coming Soon" ? (
                    <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-green-600">
                        Coming Soon
                    </div>
                ) : null}

                {paidPlan && community.preLeasing ? (
                    <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-yellow-500">
                        Pre-Leasing
                    </div>
                ) : null}

                {paidPlan ? (
                    <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400">
                        Premium
                    </div>
                ) : null}
            </div>

            <div className="flex items-center space-x-2">
                <div className="flex items-center">
                    {numberOfDocuments > 1 || (preview && enableEdit) ? (
                        <ViewButton onClick={() => setOpenGallery(2)}>
                            View {numberOfDocuments} Files
                        </ViewButton>
                    ) : null}
                    {preview && enableEdit ? (
                        paidPlan ? (
                            <Link
                                href={
                                    "/community-portal/my-communities/" +
                                    community.cpUrlId +
                                    "/documents"
                                }
                            >
                                <a className="px-1 py-1 rounded hover:bg-primary-100">
                                    <PencilIcon className="w-5 h-5 text-primary-500" />
                                </a>
                            </Link>
                        ) : (
                            <div>
                                <PaidLabel
                                    size="sm"
                                    community={community}
                                    enableButton={false}
                                />
                            </div>
                        )
                    ) : null}
                </div>

                <div className="flex items-center">
                    {numberOfVideos > 0 || (preview && enableEdit) ? (
                        <ViewButton onClick={() => setOpenGallery(1)}>
                            View {numberOfVideos} Videos
                        </ViewButton>
                    ) : null}

                    {preview && enableEdit ? (
                        <Link
                            href={
                                "/community-portal/my-communities/" +
                                community.cpUrlId +
                                "/videos"
                            }
                        >
                            <a className="px-1 py-1 rounded hover:bg-primary-100">
                                <PencilIcon className="w-5 h-5 text-primary-500" />
                            </a>
                        </Link>
                    ) : null}
                </div>

                <div className="flex items-center">
                    {numberOfImages > 1 || (preview && enableEdit) ? (
                        <ViewButton onClick={() => setOpenGallery(0)}>
                            View {numberOfImages} Photos
                        </ViewButton>
                    ) : null}

                    {preview && enableEdit ? (
                        <Link
                            href={
                                "/community-portal/my-communities/" +
                                community.cpUrlId +
                                "/images"
                            }
                        >
                            <a className="px-1 py-1 rounded hover:bg-primary-100">
                                <PencilIcon className="w-5 h-5 text-primary-500" />
                            </a>
                        </Link>
                    ) : null}
                </div>
            </div>

            <CommunityActions community={community} dark={false} phone={phone} />
        </div>
    );
};

const ViewButton = ({ children, onClick }) => {
    return (
        <button
            onClick={onClick}
            className="px-2 py-0.5 text-sm text-primary-50 rounded bg-primary-500 hover:bg-primary-400"
        >
            {children}
        </button>
    );
};

export default CommunitySecondaryHeader;
