import React, { useState } from "react";
import Image from "next/legacy/image";
import Link from "@/utils/link";

import { PencilIcon } from "@heroicons/react/outline";
import Verified from "../Logo/Verified";

import { imageLoader, removeExt, PdImage } from "@/utils/image";
import CommunityDialogGalleryOnboarded from "./CommunityDialogGalleryOnboarded";
import { PaidLabel } from "../communityPortal/community/EditUtils";

/**
 * https://cdn.purpledoorfinders.com/1.jpg
 *
 * /Activities 91
 * /Floor 513
 * /Food 89
 * /Front 721
 * /Room 95
 * /States 2 letter state code CA.jpg  NJ.jpg etc
 *
 * FILENAME-th.jpg = 640x480
 * FILENAME-tm.jpg = 320x480
 * FILENAME.jpg = orig
 */

const CommunityMainGalleryOnboarded = ({
    community,
    preview = false,
    enableEdit = false,
    open = false,
    onClose = () => {},
    sidebar = false,
}) => {
    //const [open, setOpen] = useState(-1);

    let numberOfImages = 0;
    let numberOfVideos = 0;
    let numberOfDocuments = 1;
    let images = [];
    if (community && community.media) {
        numberOfImages = community.media.filter(
            (m) => m.mediaType !== "Video" && m.mediaType !== "Document"
        ).length;
        images = community.media.filter(
            (m) => m.mediaType !== "Video" && m.mediaType !== "Document"
        );

        numberOfVideos = community.media.filter((m) => m.mediaType === "Video").length;

        numberOfDocuments += community.media.filter(
            (m) => m.mediaType === "Document"
        ).length;

        numberOfDocuments += community.brochureUrl ? 1 : 0;
        numberOfDocuments += community.careInfoUrl ? 1 : 0;
        numberOfDocuments += community.menuUrl ? 1 : 0;
        numberOfDocuments += community.calendarUrl ? 1 : 0;
    }

    return (
        <>
            <div
                id="id-section-overview"
                className={`relative ${
                    sidebar ? "mt-0" : "mt-16"
                } h-[300px] sm:h-[400px] rounded overflow-hidden bg-gray-100`}
            >
                <Images images={images} />

                {/*community.plan === "Paid" ? (
                    <div className="absolute top-2 left-2">
                        <div className="px-2 py-0.5 flex items-center bg-white rounded">
                            <Verified width={18} height={22} />
                            <span className="ml-1 font-bold text-primary-500">
                                VERIFIED
                            </span>
                        </div>
                    </div>
                ) : null*/}

                {/*<button
                    className="absolute right-4 bottom-2 font-bold bg-primary-50 px-2 py-1 rounded hover:bg-primary-100"
                    onClick={() => setOpen(0)}
                >
                    View {numberOfImages} Photos
                </button>

                {(preview && enableEdit) || numberOfVideos > 0 ? (
                    <button
                        className="absolute right-36 bottom-2 font-bold bg-primary-50 px-2 py-1 rounded hover:bg-primary-100"
                        onClick={() => setOpen(1)}
                    >
                        View{" "}
                        {numberOfVideos > 1
                            ? numberOfVideos + " Video"
                            : numberOfVideos + " Videos"}
                    </button>
                ) : null}

                {(preview && enableEdit) || numberOfDocuments > 0 ? (
                    <button
                        className="absolute right-[264px] bottom-2 flex items-center font-bold bg-primary-50 px-2 py-1 rounded hover:bg-primary-100"
                        onClick={() => setOpen(2)}
                        disabled={preview && enableEdit && community.plan !== "Paid"}
                    >
                        View{" "}
                        {numberOfDocuments > 1
                            ? numberOfDocuments + " Documents"
                            : numberOfDocuments + " Document"}
                        {preview && enableEdit ? (
                            <PaidLabel size="sm" community={community} />
                        ) : null}
                    </button>
                ) : null}

                {preview && enableEdit ? (
                    <Link
                        href={
                            "/community-portal/my-communities/" +
                            (community ? community.cpUrlId : "") +
                            "/images"
                        }
                    >
                        <a className="absolute right-4 bottom-12 bg-primary-50 px-2 py-1 rounded hover:bg-primary-100">
                            <PencilIcon className="w-6 h-6 text-primary-500" />
                        </a>
                    </Link>
                ) : null}

                {preview && enableEdit ? (
                    <Link
                        href={
                            "/community-portal/my-communities/" +
                            (community ? community.cpUrlId : "") +
                            "/videos"
                        }
                    >
                        <a className="absolute right-36 bottom-12 bg-primary-50 px-2 py-1 rounded hover:bg-primary-100">
                            <PencilIcon className="w-6 h-6 text-primary-500" />
                        </a>
                    </Link>
                ) : null}

                {preview && enableEdit && community.plan === "Paid" ? (
                    <Link
                        href={
                            "/community-portal/my-communities/" +
                            community.cpUrlId +
                            "/documents"
                        }
                    >
                        <a className="absolute right-[264px] bottom-12 bg-primary-50 px-2 py-1 rounded hover:bg-primary-100">
                            <PencilIcon className="w-6 h-6 text-primary-500" />
                        </a>
                    </Link>
                    ) : null}*/}
            </div>

            {open >= 0 ? (
                <CommunityDialogGalleryOnboarded
                    open={true}
                    onClose={onClose}
                    community={community}
                    initialTab={open}
                />
            ) : null}
        </>
    );
};

const GalleryImage = ({ src, alt, className, size = "xs" }) => {
    return (
        <div className={"relative " + className}>
            <PdImage src={removeExt(src) + `.${size}.jpg`} alt={alt} />
        </div>
    );

    /*return (
        <div className={"relative " + className}>
            <Image
                loader={imageLoader}
                src={removeExt(src) + `.${size}.jpg`}
                alt={alt}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                priority
                unoptimized={true}
            />
        </div>
    );*/
};

const Images = ({ images }) => {
    if (images.length === 0) {
        return <div></div>;
    }

    const imageUrls = [];

    // 1. get the main image
    let mainImage = images.find((m) => m.type === "Main");
    if (mainImage) {
        imageUrls.push(mainImage.url);
    }

    // 2. get the community images
    images
        .filter((m) => m.type === "Community")
        .forEach((m) => {
            imageUrls.push(m.url);
        });

    // 3. get the amenities images
    images
        .filter((m) => m.type === "Amenity")
        .forEach((m) => {
            imageUrls.push(m.url);
        });

    // 4. get the activities images
    images
        .filter((m) => m.type === "Activity")
        .forEach((m) => {
            imageUrls.push(m.url);
        });

    // 5. get the activities images
    images
        .filter((m) => m.type === "Dining")
        .forEach((m) => {
            imageUrls.push(m.url);
        });

    // 6. get the unit images
    images
        .filter((m) => m.type === "Unit")
        .forEach((m) => {
            imageUrls.push(m.url);
        });

    if (imageUrls.length === 1) {
        return (
            <div className="grid grid-cols-1 gap-2">
                <GalleryImage
                    src={imageUrls[0]}
                    alt="Community Picture"
                    className="w-full h-[300px] sm:h-[400px]"
                    size="sm"
                />
            </div>
        );
    }

    if (imageUrls.length === 2) {
        return (
            <div className="grid grid-cols-2 gap-2">
                <GalleryImage
                    src={imageUrls[0]}
                    alt="Community Picture"
                    className="w-full h-[300px] sm:h-[400px]"
                    size="sm"
                />

                <GalleryImage
                    src={imageUrls[1]}
                    alt="Community Picture"
                    className="w-full h-[300px] sm:h-[400px]"
                />
            </div>
        );
    }

    if (imageUrls.length === 3) {
        return (
            <div className="grid grid-cols-4 gap-2">
                <GalleryImage
                    src={imageUrls[0]}
                    alt="Community Picture"
                    className="w-full h-[300px] sm:h-[400px] col-span-2 row-span-2"
                    size="sm"
                />

                <GalleryImage
                    src={imageUrls[1]}
                    alt="Community Picture"
                    className="w-full h-[146px] sm:h-[196px] col-span-2"
                />

                <GalleryImage
                    src={imageUrls[2]}
                    alt="Community Picture"
                    className="w-full h-[146px] sm:h-[196px] col-span-2"
                />
            </div>
        );
    }

    if (imageUrls.length === 4) {
        return (
            <div className="grid grid-cols-4 gap-2">
                <GalleryImage
                    src={imageUrls[0]}
                    alt="Community Picture"
                    className="w-full h-[300px] sm:h-[400px] col-span-2 row-span-2"
                    size="sm"
                />

                <GalleryImage
                    src={imageUrls[1]}
                    alt="Community Picture"
                    className="w-full h-[146px] sm:h-[196px] col-span-2"
                />

                <GalleryImage
                    src={imageUrls[2]}
                    alt="Community Picture"
                    className="w-full h-[146px] sm:h-[196px]"
                />

                <GalleryImage
                    src={imageUrls[3]}
                    alt="Community Picture"
                    className="w-full h-[146px] sm:h-[196px]"
                />
            </div>
        );
    }

    // 5 or more
    return (
        <div className="grid grid-cols-4 gap-2">
            <GalleryImage
                src={imageUrls[0]}
                alt="Community Picture"
                className="w-full h-[300px] sm:h-[400px] col-span-2 row-span-2"
                size="sm"
            />

            <GalleryImage
                src={imageUrls[1]}
                alt="Community Picture"
                className="w-full h-[146px] sm:h-[196px]"
            />

            <GalleryImage
                src={imageUrls[2]}
                alt="Community Picture"
                className="w-full h-[146px] sm:h-[196px]"
            />

            <GalleryImage
                src={imageUrls[3]}
                alt="Community Picture"
                className="w-full h-[146px] sm:h-[196px]"
            />

            <GalleryImage
                src={imageUrls[4]}
                alt="Community Picture"
                className="w-full h-[146px] sm:h-[196px]"
            />
        </div>
    );
};

export default CommunityMainGalleryOnboarded;
