import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

import { GoogleMap as GoogleMapComponent, useJsApiLoader } from "@react-google-maps/api";

import HomeHeader from "../home/HomeHeader";
import CommunitySecondaryHeader from "./CommunitySecondaryHeader";

import CommunityMainGallery from "./CommunityMainGallery";
import CommunityMainInfo from "./CommunityMainInfo";

import CommunityMainGalleryOnboarded from "./CommunityMainGalleryOnboarded";
import CommunityMainInfoOnboarded from "./CommunityMainInfoOnboarded";
import CommunityMainInfoAiImported from "./CommunityMainInfoAiImported";

import { useWindowSize } from "@/utils/hooks";

// Define a list of libraries to load from the Google Maps API
const libraries = ["places", "drawing", "geometry"];

const CommunityPage = ({ community, articles, sidebar = false, onClose }) => {
    const router = useRouter();
    const { height } = useWindowSize();
    const [place, setPlace] = useState({});
    const [imageUrls, setImageUrls] = useState([]);
    const [openGallery, setOpenGallery] = useState(-1);
    const [googleMap, setGoogleMap] = useState(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
        libraries,
    });

    const onLoad = React.useCallback(function callback(map) {
        setGoogleMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setGoogleMap(null);
    }, []);

    useEffect(() => {
        const getGoogleData = (enablePhotos) => {
            const google = window.google;

            const service = new google.maps.places.PlacesService(
                document.getElementById("id-community-" + community.nameId)
            );

            const fields = [
                "name",
                "type",
                //"photo",
                "url",
                "formatted_phone_number",
                "website",
                "rating",
                "user_ratings_total",
            ];

            if (enablePhotos) {
                fields.push("photo");
            }

            service.getDetails(
                {
                    placeId: community.communityPlaceId,
                    fields,
                },
                function (place, status) {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        console.log("google map result:", place);
                        setPlace(place);

                        const urls = [];

                        if (enablePhotos && place.photos && place.photos.length > 0) {
                            for (let i = 0; i < 10; i++) {
                                if (i < place.photos.length) {
                                    const url = place.photos[i].getUrl();
                                    if (url) {
                                        urls.push(url);
                                    }
                                }
                            }
                            setImageUrls(urls);
                        }
                    }
                }
            );
        };

        // reset the images when the community changes
        setImageUrls([]);

        // this is needed for testing:
        // wait 1 sec to make sure winodw.google is loaded
        if (
            googleMap !== null &&
            community.onboarded !== true &&
            //community.aiImported !== true &&
            community.communityPlaceId
        ) {
            getGoogleData(community.aiImported !== true);
        }
    }, [community.onboarded, community.aiImported, community.communityPlaceId, googleMap]);

    if (community.onboarded) {
        if (sidebar) {
            return (
                <div style={{ height: height - 64, overflowY: "scroll" }}>
                    <div className="px-8">
                        <div className="mt-0">
                            <CommunityMainGalleryOnboarded
                                community={community}
                                open={openGallery}
                                onClose={() => setOpenGallery(-1)}
                                sidebar={true}
                            />
                        </div>
                        <div className="mt-2">
                            <CommunitySecondaryHeader
                                community={community}
                                sidebar={true}
                                setOpenGallery={setOpenGallery}
                                preview={false}
                                enableEdit={false}
                                onClose={onClose}
                            />
                        </div>
                        <div>
                            <CommunityMainInfoOnboarded
                                community={community}
                                sidebar={true}
                                onClose={onClose}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <HomeHeader fixed={true} />

                <div className="container mx-auto pt-12 px-4 sm:px-16">
                    <div className="mt-6">
                        <CommunityMainGalleryOnboarded
                            community={community}
                            open={openGallery}
                            onClose={() => setOpenGallery(-1)}
                        />
                    </div>
                    <div className="mt-2">
                        <CommunitySecondaryHeader
                            community={community}
                            setOpenGallery={setOpenGallery}
                            preview={false}
                            enableEdit={false}
                        />
                    </div>
                    <div>
                        <CommunityMainInfoOnboarded
                            community={community}
                            onClose={() => router.back()}
                        />
                    </div>
                </div>
            </div>
        );
    } else if (community.aiImported) {
        if (sidebar) {
            return (
                <div style={{ height: height - 64, overflowY: "scroll" }}>
                    <div className="px-8">
                        <div className="mt-0">
                            <CommunityMainGalleryOnboarded
                                community={community}
                                open={openGallery}
                                onClose={() => setOpenGallery(-1)}
                                sidebar={true}
                            />
                        </div>
                        <div className="mt-2">
                            <CommunitySecondaryHeader
                                community={community}
                                sidebar={true}
                                setOpenGallery={setOpenGallery}
                                preview={false}
                                enableEdit={false}
                                onClose={onClose}
                            />
                        </div>
                        <div>
                            <CommunityMainInfoAiImported
                                community={community}
                                sidebar={true}
                                onClose={onClose}
                                articles={articles}
                                place={place}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <HomeHeader fixed={true} />

                <div className="container mx-auto pt-12 px-4 sm:px-16">
                    <div className="mt-6">
                        <CommunityMainGalleryOnboarded
                            community={community}
                            open={openGallery}
                            onClose={() => setOpenGallery(-1)}
                        />
                    </div>
                    <div className="mt-2">
                        <CommunitySecondaryHeader
                            community={community}
                            setOpenGallery={setOpenGallery}
                            preview={false}
                            enableEdit={false}
                        />
                    </div>
                    <div>
                        <CommunityMainInfoAiImported
                            community={community}
                            onClose={() => router.back()}
                            articles={articles}
                            place={place}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        if (sidebar) {
            return (
                <div style={{ height: height - 64, overflowY: "scroll" }}>
                    <div className="px-8">
                        <div className="mt-0">
                            <CommunityMainGallery
                                community={community}
                                place={place}
                                imageUrls={imageUrls}
                                open={openGallery}
                                onClose={() => setOpenGallery(-1)}
                            />
                        </div>
                        <div className="mt-2">
                            <CommunitySecondaryHeader
                                community={community}
                                sidebar={true}
                                setOpenGallery={setOpenGallery}
                                preview={false}
                                enableEdit={false}
                                phone={place.formatted_phone_number}
                                onClose={onClose}
                            />
                        </div>
                        <div>
                            <CommunityMainInfo
                                community={community}
                                place={place}
                                articles={articles}
                                sidebar={true}
                                onClose={onClose}
                            />
                        </div>
                    </div>

                    {/* This is needed for the google place library */}
                    <div id={"id-community-" + community.nameId} className="hidden w-0 h-0">
                        {isLoaded ? (
                            <GoogleMapComponent onLoad={onLoad} onUnmount={onUnmount} />
                        ) : null}
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <HomeHeader fixed={true} />

                <div className="container mx-auto pt-12 px-4 sm:px-16">
                    <div className="mt-6">
                        <CommunityMainGallery
                            community={community}
                            place={place}
                            open={openGallery}
                            imageUrls={imageUrls}
                            onClose={() => setOpenGallery(-1)}
                        />
                    </div>
                    <div className="mt-2">
                        <CommunitySecondaryHeader
                            community={community}
                            setOpenGallery={setOpenGallery}
                            preview={false}
                            enableEdit={false}
                            phone={place.formatted_phone_number}
                        />
                    </div>
                    <div>
                        <CommunityMainInfo
                            community={community}
                            place={place}
                            articles={articles}
                            onClose={() => router.back()}
                        />
                    </div>
                </div>

                {/* This is needed for the google place library */}
                <div id={"id-community-" + community.nameId} className="hidden w-0 h-0">
                    {isLoaded ? (
                        <GoogleMapComponent onLoad={onLoad} onUnmount={onUnmount} />
                    ) : null}
                </div>
            </div>
        );
    }
};

export default CommunityPage;
