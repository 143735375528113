import Link from "@/utils/link";
import Image from "next/legacy/image";
import { imageLoader, removeExt, PdImage } from "@/utils/image";

const ArticleCard = ({ article, imageHeight = "h-32" }) => {
    return (
        <div className="border border-gray-200 rounded shadow hover:shadow-xl hover:bg-gray-100">
            <Link href={"/resources/articles/" + article.articleId}>
                <a className="">
                    {article.mainImageUrl ? (
                        <div className={`relative w-full ${imageHeight}`}>
                            <PdImage
                                src={removeExt(article.mainImageUrl) + ".sm.jpg"}
                                originalSrc={article.mainImageUrl}
                                alt={article.title}
                            />
                            {/*<Image
                                loader={imageLoader}
                                src={removeExt(article.mainImageUrl) + ".sm.jpg"}
                                alt={article.title}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="center"
                                priority
                                unoptimized={false}
                            />*/}
                        </div>
                    ) : (
                        <div className={`w-full ${imageHeight} bg-gray-200`}></div>
                    )}

                    <div className="relative w-full h-32 px-4 pb-2">
                        {/*<div className="mt-2 font-headline font-bold text-sm text-gray-500 uppercase">
                            {article.keywords &&
                                article.keywords.split(";").map((k, i) => (
                                    <span key={k}>
                                        {i > 0 ? (
                                            <span className="mx-2">&bull;</span>
                                        ) : null}
                                        {k}
                                    </span>
                                ))}
                                        </div>*/}

                        <h3 className="mt-1 font-headline font-bold text-gray-700">
                            {article.title}
                        </h3>

                        <div className="absolute bottom-1 text-sm font-extrabold uppercase text-primary-500">
                            Read More
                        </div>
                    </div>
                </a>
            </Link>
        </div>
    );
};

export default ArticleCard;
