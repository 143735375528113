import React from "react";

import { StarIcon } from "@heroicons/react/solid";

const Rating = ({ rating = 0 }) => {
    return (
        <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
                <span key={star}>
                    <StarIcon
                        className={`w-4 h-4 ${
                            rating >= star ? "text-yellow-500" : "text-gray-300"
                        }`}
                    />
                </span>
            ))}
        </div>
    );
};

export default Rating;
