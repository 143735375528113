import React, { useState, useEffect } from "react";

import Dialog from "@/ui/Dialog";
import { XIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

import { IconButton } from "../ui/Button";

import { useWindowSize } from "../../utils/hooks";

const CommunityDialogGallery = ({ open, onClose, community, imageUrls }) => {
    const { height } = useWindowSize();
    const [images, setImages] = useState(
        imageUrls.map((url, index) => ({ src: url, _id: index }))
    );
    const [selectedId, setSelectedId] = useState(0);

    const scrollStyle = { height: height ? height - 160 : "100%" };

    const handleSelect = (id) => {
        setSelectedId(id);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="fixed z-40 top-16 bottom-0 left-3 right-3 sm:top-20 sm:bottom-6 sm:left-6 sm:right-6 overflow-y-auto"
        >
            <div className="flex items-center justify-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                <div className="z-50 bg-gray-100 w-full border rounded-xl">
                    <div className="flex flex-col justify-between">
                        <div className="h-12 border-b border-gray-300">
                            <div className="pl-6 pr-2 py-1 flex justify-between items-center">
                                <div className="flex space-x-6">Community Images</div>
                                <div>
                                    <IconButton onClick={onClose}>
                                        <XIcon className="w-6 h-6" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        <div
                            className="relative p-6 flex justify-between"
                            style={scrollStyle}
                        >
                            <LeftGallery
                                community={community}
                                images={images}
                                onSelect={handleSelect}
                                selectedId={selectedId}
                            />
                            <RightPreview
                                community={community}
                                images={images}
                                onSelect={handleSelect}
                                selectedId={selectedId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const Image = ({ image, onSelect, full, selectedId }) => {
    let border = "";

    if (image._id === selectedId) {
        border = " box-border border-4 border-primary-500";
    }

    if (full) {
        return (
            <button
                onClick={() => onSelect(image._id)}
                className="w-full h-48 sm:col-span-2"
            >
                <img
                    src={image.src}
                    alt={image.type}
                    className={"w-full h-48 object-cover rounded" + border}
                />
            </button>
        );
    } else {
        return (
            <button
                key={image.src}
                onClick={() => onSelect(image._id)}
                className="w-full h-48 sm:h-32"
            >
                <img
                    src={image.src}
                    alt={image.type}
                    className={"w-full h-48 sm:h-32 object-cover rounded" + border}
                />
            </button>
        );
    }
};

const LeftGallery = ({ community, images, onSelect, selectedId }) => {
    return (
        <div
            id="id-left-gallery"
            className="sm:pr-6 w-full sm:w-[408px] flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-y-scroll"
        >
            {images.map((image, index) => {
                return (
                    <Image
                        key={image._id}
                        image={image}
                        onSelect={onSelect}
                        selectedId={selectedId}
                        full={index % 3 === 2}
                    />
                );
            })}
        </div>
    );
};

const RightPreview = ({ community, images, selectedId, onSelect }) => {
    const imageIndex = images.findIndex((img) => img._id === selectedId);
    const image = images[imageIndex];
    const address = community.contact[0].address[0];

    const handleLeft = () => {
        if (imageIndex > 0) {
            onSelect(images[imageIndex - 1]._id);
        }
    };

    const handleRight = () => {
        if (imageIndex < images.length - 1) {
            onSelect(images[imageIndex + 1]._id);
        }
    };

    if (image) {
        return (
            <>
                <div className="hidden sm:block flex-grow">
                    <div className="w-full h-full mt-0 flex flex-col justify-between">
                        <div className="flex-grow-0">
                            <div className="flex justify-center">
                                <h2 className="text-2xl">{community.name}</h2>
                            </div>

                            <div className="mt-2 flex justify-center">
                                <h2 className="text-md text-gray-500">
                                    {address.street},{" "}
                                    <span className="text-gray-800">{address.city}</span>,{" "}
                                    <span className="text-gray-800">{address.state}</span>{" "}
                                    {address.postalCode}
                                </h2>
                            </div>
                        </div>

                        <div
                            className="mt-4 flex-2 flex justify-center"
                            style={{ maxHeight: "75%" }}
                        >
                            <img
                                src={image.src}
                                alt={image.type}
                                className="object-cover rounded-xl"
                                style={{ width: "100%", maxWidth: "100%" }}
                            />
                        </div>

                        <div className="mb-2 flex items-center justify-center">
                            <div className="p-0 flex space-x-4 items-center border-2 border-gray-600 rounded-full">
                                <div className="pr-2 border-r border-gray-400">
                                    <IconButton onClick={handleLeft}>
                                        <ChevronLeftIcon className="w-6 h-6" />
                                    </IconButton>
                                </div>

                                <div>
                                    {imageIndex + 1} / {images.length}
                                </div>

                                <div className="pl-2 border-l border-gray-400">
                                    <IconButton onClick={handleRight}>
                                        <ChevronRightIcon className="w-6 h-6" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="pt-8 w-full h-full sm:hidden absolute inset-0 flex justify-between items-center bg-black bg-opacity-80"
                    style={
                        {
                            /*maxHeight: "75%"*/
                        }
                    }
                >
                    <div className="relative w-full h-full">
                        <img
                            src={image.src}
                            alt={image.type}
                            className="object-cover rounded-xl"
                            style={{ width: "100%", maxWidth: "100%" }}
                        />

                        <div className="absolute top-2 right-2">
                            <button
                                onClick={() => onSelect(null)}
                                className="px-4 py-2 text-base bg-primary-500 text-white rounded-lg"
                            >
                                Close Image
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default CommunityDialogGallery;
